import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TablePagination from '@mui/material/TablePagination';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import { useStyles } from "./styles";
import {get, post} from "./commons";

export default function WorkReport({biz,shiftsigned,managers,projects,globalVariables,changeGlobalVariables}) {
  const classes = useStyles();
  const [totalRows, setTotalRows] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [exportData, setExportData] = React.useState([]);
  const [displayexportData, setDisplayexportData] = React.useState([]);
  const [exportModel, setExportModel] = React.useState();
  const [workHoursModel, setWorkHoursModel] = React.useState();
  const [isSaved, setisSaved] = React.useState(false);
  const [exportType, setExportType] = React.useState(0);
  const [onlyErrors, setOnlyErrors] = React.useState(false);
  const [allChecked, setAllChecked] = React.useState(false);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [leaveModel, setLeaveModel] = React.useState();

  const padLeft = (n) => ("00" + n).slice(-2);
  const today = new Date();
  const todayFormated = today.getFullYear() + "-" + padLeft(1+today.getMonth()) + "-" + padLeft(today.getDate());
  const [startDate, setStartDate] = React.useState(todayFormated);
  const [endDate, setEndDate] = React.useState(todayFormated);

  const displayDate = (date) => {
    let newDate = new Date(date);
    if (newDate) return newDate.toLocaleDateString('es-ES', { month: "2-digit", day: "2-digit", year: "numeric" })
  }

  const changeStartDate = (e) => {
    let newValue = e.target.value;
    setStartDate(newValue);
    retriveDataDB(exportType,newValue,endDate);
  }
  const changeEndDate = (e) => {
    let newValue = e.target.value;
    setEndDate(newValue);
    retriveDataDB(exportType,startDate,newValue);
  }

  const retriveDataDB = (state,start,end) => {
    let query = `/server/request/getexportfullset?state=${state}`;
    if (state === 1) {
      let currentStart = (start) ? start : startDate;
      let currentEnd = (end) ? end : endDate;
      query += `&start=${currentStart}&end=${currentEnd}`;
      }
    setExportType(state);
    get(query).then((response)=>{
        if (response.data) {
          setExportData(response.data);
          //setDisplayexportData(response.data.slice(0,rowsPerPage));
          setDisplayexportData(response.data);
          setTotalRows(response.data.length);
        }
    });
    setCheckedItems([]);
    setAllChecked(false);
  }

  useEffect(()=>{
    get(`/server/request/getexportmodel`)
    .then((response) =>{
      if (response.exportmodel) setExportModel(response.exportmodel);
      if (response.workhoursmodel && response.workhoursmodel.elements) {
        let wkmodel = response.workhoursmodel.elements.reduce((a,c)=>{
          if (c.def) {
            a[c.def] = c.id
            return a;
          }
          else return null;
        },{})
        setWorkHoursModel(wkmodel);
      }
      if (response && response.leavemodel) {
        setLeaveModel(response.leavemodel);
      }
      retriveDataDB(0);
    });
  },[]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    let start = newPage*rowsPerPage;
    setDisplayexportData(exportData.slice(start,newPage*rowsPerPage+rowsPerPage));
  };
  const handleChangeRowsPerPage = (event) => {
    let rows = parseInt(event.target.value, 10);
    setRowsPerPage(rows);
    setPage(0);
    setDisplayexportData(exportData.slice(0,rows));
  };
  const changeChecked = (i) => {
    let newcheckedItems = [...checkedItems];
    newcheckedItems[i] = (newcheckedItems[i]) ? !newcheckedItems[i] : true;
    setCheckedItems(newcheckedItems);
  }
  const changeAllChecked = (e,value = !allChecked) => {
    let newcheckedItems = displayexportData.map((e)=>{
      return value;
    })
    setCheckedItems(newcheckedItems);
    setAllChecked(!allChecked);
  }
  const enableOnlyErrors = () =>{
    if (!onlyErrors) changeAllChecked(null,false);
    setOnlyErrors(!onlyErrors);
    setCheckedItems([]);
    setAllChecked(false);
  }
  const removesecond = (timing) => {
    let timeT = timing.split(":");
    if (timeT.length > 1) return `${timeT[0]}:${timeT[1]}`
    else return timing
  }
  const getTime = (t) => {
    if (t) {
      let timeSet = new Date(t);
      let h = timeSet.getHours();
      if (h<10) h = `0${h}`;
      let m = timeSet.getMinutes();
      if (m<10) m = `0${m}`;
      return `${h}:${m}`;
    }
    else return ""
  }
  const retriveValues = (numb,exportModelData,row,wo,rowDailyHours,hourtype,pos,subpos,workOrders,conditional) => {
      let currentDate = displayDate(row.date);
      let fields2include = [];
      let fields2includeNumbers = (data,decimals=0,separator) => {
        let data2insert = (!isNaN(decimals) && decimals > 0) ? parseFloat(data.toFixed(decimals)) : parseInt(data);
        if (separator) fields2include.push(data2insert.toString().replace(".",separator));
        else fields2include.push(data2insert);
      }
      exportModelData.fields.forEach((type)=>{
        if (type.field) {
          if (type.field === "employee") fields2include.push(row.employee) 
          else if (type.field === "date") {
            if (type.dateformat && type.dateformat === "DD/MM/YYYY") fields2include.push(currentDate);
            else fields2include.push(row.date);
          }
          else if (type.field === "time_started" && row.holidays === 0 && row.paidleave === "0") {
            let timeStarted = (exportModelData.shift_starts) ? exportModelData.shift_starts : "00:00";
            if (row.time_started) timeStarted = row.time_started;
            else if (row.timings) {
              let timings = JSON.parse(row.timings);
              if (timings?.times && timings.times[0]?.start) timeStarted = getTime(timings.times[0].start);
            }
            let totalWorkedHoursInWO = 0;
            for (let z=0;z<subpos+1;z++) {console.log(numb,z,workHoursModel.dailyhours,workOrders)
              if (workOrders) {
                let rowDailyPrevHours = workOrders[z][workHoursModel.dailyhours];
                let keyhourHasAppeared = false;
                let totalWorkedHoursInWOSubPos = Object.keys(rowDailyPrevHours).reduce((a,c)=>{
                  if (c === hourtype) keyhourHasAppeared = true;
                  if (z<subpos || !keyhourHasAppeared) a+=rowDailyPrevHours[c];
                  return a;
                },0);
                totalWorkedHoursInWO += totalWorkedHoursInWOSubPos;
              }
            }
            let timeStartedNew = timeStarted.split(":");
            timeStartedNew[0] = parseInt(timeStartedNew[0]) + parseInt(totalWorkedHoursInWO);
            timeStartedNew[1] = parseInt(timeStartedNew[1]) + parseInt(60 *(totalWorkedHoursInWO % 1));
            if (timeStartedNew[1] > 60) {
                timeStartedNew[1] -= 60;
                timeStartedNew[0] += 1
            }
            //console.log(timeStartedNew,totalWorkedHoursInWO)
            if (timeStartedNew[0] < 10) timeStartedNew[0] = "0" + timeStartedNew[0];
            if (timeStartedNew[1] < 10) timeStartedNew[1] = "0" + timeStartedNew[1];
            timeStarted = timeStartedNew.join(":");//console.log(pos,subpos,totalWorkedHoursInWO,timeStarted)
            if (type.dateformat && type.dateformat === "DD/MM/YYYY HH:MM:SS") fields2include.push(`${currentDate} ${timeStarted}`);
            else if (type.dateformat && type.dateformat === "HH:MM") fields2include.push(removesecond(timeStarted));
            else fields2include.push(`${row.date} ${timeStarted}`);
          }
          else if (type.field === "time_finished" && row.holidays === 0 && row.paidleave === "0") {
            if (type.dateformat && type.dateformat === "DD/MM/YYYY HH:MM:SS") fields2include.push(`${currentDate} ${row.time_finished}`);
            else if (type.dateformat && type.dateformat === "HH:MM") fields2include.push(removesecond(row.time_finished));
            else fields2include.push(`${row.date} ${row.time_finished}`);
          }
          else if (type.field === "workedday") {
            if (type.workday && row.holidays === 0 && row.paidleave === "0") fields2include.push(type.workday);
            else if (type.holidays && row.holidays === 1 && row.paidleave === "0") fields2include.push(type.holidays);
            else if (type.workpermit && row.holidays === 0 && row.paidleave !== "0") fields2include.push(type.workpermit);
            else fields2include.push("");
          }
          else fields2include.push("");
        }
        else if (type.work_items) {
          if (rowDailyHours && !isNaN(rowDailyHours[hourtype]) && rowDailyHours[hourtype]>0 && type.work_items === "worked_hours") fields2includeNumbers(rowDailyHours[hourtype],type.decimals,type.separator);
          else if (wo && type.work_items === "working_order") fields2include.push(wo.workorder);
          else if (hourtype && type.work_items === "hours_type") fields2include.push(hourtype);
          else if (type.work_items === "kilometers") {
            let kilometersName = (workHoursModel && workHoursModel.kilometers) ? workHoursModel.kilometers : null;
            if (kilometersName) fields2includeNumbers(wo[kilometersName],type.decimals,type.separator);//console.log(wo,kilometersName,wo[kilometersName])
            else fields2include.push("");
          }
          else if (type.work_items === "kilometerscost" && type.multiply_by) {
            let kilometersName = (workHoursModel && workHoursModel.kilometers) ? workHoursModel.kilometers : null;
            if (kilometersName) fields2includeNumbers(wo[kilometersName] * type.multiply_by,type.decimals,type.separator);
            else fields2include.push("");
          }
          else fields2include.push("");
        }
        else if (type.query) {
          if (type.query === "codition-return") {
            fields2include.push(conditional);
          }
          else if (type.query === "employee" && type.options) {
            type.options.forEach((f)=>{//console.log(type,f,wo,(f.ifmanager && wo && wo.manager && f.ifmanager === wo.manager),(f.ifnotmanager && wo && wo.manager && f.ifnotmanager !== wo.manager))
              if (f.ifmanager && wo && wo.manager && f.ifmanager === wo.manager) fields2include.push(f.val);
              else if (f.ifnotmanager && wo && wo.manager && f.ifnotmanager !== wo.manager) fields2include.push(f.val);
            })
          }
          else if (type.query === "allowances" && type.options) {
            let value = 0;
            type.options.forEach((f)=>{
              if (value === 0 && f.type && wo && wo.Dietas && f.type === wo.Dietas) value = f.val;
            });
            fields2include.push(value);
          }
          else if (type.query === "paidleave&holidays" && type.options) {
            if (row.paidleave !== "0" || row.holidays === 1) {
              type.options.forEach((f)=>{//console.log(f.if,row.paidleave,row.holidays)
                if (f.if && f.if === "holidays" && row.holidays === 1) fields2include.push(f.val);
                else if (f.if && row.paidleave === f.if) fields2include.push(f.val);
              });
            }
            else fields2include.push("");
          }
          else fields2include.push("");
        }
        else if (type.static) fields2include.push(type.static);
        else fields2include.push("");
 // if (conditional) //console.log(conditional,type,fields2include)
      });
      let allGood = true;
      exportModelData.fields.forEach((type,index)=>{//console.log(type.mandatory , fields2include[index],!fields2include[index])
        if (type.mandatory && !fields2include[index]) allGood = false;
      });
      if (allGood) return fields2include;
      else return;
  }
  const downloadFile= (pos, buttonName) => {
   let exportModelData = exportModel.export[pos];
   let delimiter = (exportModelData.divider) ? exportModelData.divider : ",";
   let csv = [];
   let listOfIdsExported = []
   displayexportData.forEach((row,i)=>{
    if (checkedItems[i]) {
      if (row.id) listOfIdsExported.push(row.id)
      let workItems = (row.work_items) ? JSON.parse(row.work_items) : {};
      let workOrders = (workItems.workorders && workItems.workorders.length > 0) ? workItems.workorders : null;
      let addrows = false;
      if (exportModelData.only_if) {
        exportModelData.only_if.forEach(e=>{
          if (e.workorders && e.vals && workOrders) {
            let workOrders2 = workOrders.map((f)=>{
              if (f[e.workorders] && e.vals.includes(f[e.workorders])) return f;
              else return null;
            }).filter(z=>z);
            if (workOrders2.length > 0) addrows = true;
          }
          if (e.type && e.valsnot && !e.valsnot.includes(row[e.type])) {
            addrows = true;
          }
          if (e.type && e.vals && row[e.type] && e.vals.includes(row[e.type])) {
            addrows = true;
          }
          if (e.work_items && e.int && workOrders) {
            let workOrders2 = workOrders.map((f)=>{
              if (workHoursModel[e.work_items] && f[workHoursModel[e.work_items]] && parseInt(f[workHoursModel[e.work_items]])>0) return f;
              else return null;
            }).filter(z=>z);
            if (workOrders2.length > 0) addrows = true;
          }
        })
      }
      else addrows = true;
      if (addrows) {//console.log(exportModelData.one_rowset_per?.includes("dailyshift"),Boolean(workOrders),workItems.workorders)
        if (exportModelData.one_rowset_per?.includes("dailyshift")) {
          let allGoods = {};
          let counter = {};
          let altcounter = {};
          workOrders?.forEach((wo,j)=>{
            exportModelData.conditions.forEach((type,pos)=>{
              if (type.required) {
                let allgood = 0;
                let ignorethis = false;
                type.required.forEach((e,l)=>{
                  if (
                       allGoods[type.return]  === undefined ||
                       (allGoods[type.return] !== true && type.comply === "any") ||
                       (allGoods[type.return] != null && (type.comply === "add" || type.comply === "all"))
                     ) {
                    let f = Object.keys(e)[0];
                    if (f === "workitems") {
                      if (e[f].exclude) {
                        let keysWO = Object.keys(e[f].exclude);
                        keysWO.forEach(x=>{
                          if ( wo[x] && wo[x][e[f].exclude[x]]) {
                            ignorethis = true; ////console.log(x, wo[x], wo[x][e[f].exclude[x]])
                          }
                        })
                      }
                      if (e.workitems.loc) {
                        let splitNames = e.workitems.loc.split(".");
                        let val = null;
                        if (splitNames.length === 1) val = wo[e.workitems.loc];
                        else if (splitNames.length === 2) val = wo[splitNames[0]][splitNames[1]];
                        if (val != null) {//console.log("pos",j,pos,e[f])
                          if (counter[pos] == null) counter[pos] = 0;
                          if (altcounter[pos] == null) altcounter[pos] = 0;
                          if (e[f].anybiggerthan != null) {
                            counter[pos] += val;
                            if (counter[pos] > e[f].anybiggerthan) allgood += 1;
                          }
                          else if (e[f].max != null) {
                            counter[pos] += val;
                            if (counter[pos] <= e[f].max) allgood += 1;
                          }
                          else if (e[f].biggest != null) {
                            let vals = wo[e.workitems.biggest];
                            if (typeof vals === 'object') {
                              let valsKeys = Object.keys(vals);
                              let newvals = valsKeys.reduce((a,c)=>{
                                a += vals[c];
                                return a;
                              },0);
                              vals = newvals;
                            }
                            if (wo[e.workitems.loc] === e[f].val) counter[pos] += vals;
                            else altcounter[pos] += vals;
                            if (counter[pos] > altcounter[pos]) allgood += 1;
                            else if (counter[pos] === altcounter[pos] && e[f].chooseifequal) allgood += 1;
                          }
                          else if (val === e[f].val){
                            allgood += 1;
                          }
                        }
                      }
                      else if (!ignorethis) allgood += 1;
                    }
                    else if (row[f] === e[f]) allgood += 1;
                  }
                  else ignorethis = true;
                });
                if (!ignorethis) allGoods[type.return] = allgood === type.required.length;
                //console.log(type.return,allgood,ignorethis,type.required.length,allGoods)
              }
            });
          });//console.log("allGoodsF",allGoods)
          let allGoodsFKeys = Object.keys(allGoods);
          allGoodsFKeys.forEach((key)=>{
            if (allGoods[key]) {
              let fields2include = retriveValues(1,exportModelData,row,null,null,null,null,null,workOrders,key);
              if (fields2include) csv.push(fields2include.join(delimiter));//console.log("1")
            }
          });
        }
        else if (workOrders) {
          if (!exportModelData.one_row_per?.includes("hours_type")) {
            workOrders.forEach((wo,j)=>{//console.log("woD",wo,row)
              let fields2include = retriveValues(2,exportModelData,row,wo,null,null,i,j,workOrders);
              if (fields2include) csv.push((fields2include).join(delimiter));//console.log("2")
            })
          }
          else if (workOrders && workHoursModel.dailyhours) {
            workOrders.forEach((wo,j)=>{
              let rowDailyHours = wo[workHoursModel.dailyhours];
              if (rowDailyHours) {
                let hourtypes = Object.keys(rowDailyHours);
                hourtypes.forEach((hourtype,k)=>{
                  if (rowDailyHours[hourtype] > 0) {
                    let fields2include = retriveValues(3,exportModelData,row,wo,rowDailyHours,hourtype,i,j,workOrders);
                    if (fields2include) csv.push((fields2include).join(delimiter));//console.log("3")
                  }
                })
                }
              })
            }
            else {
          //    let fields2include = retriveValues(exportModelData,row,null,null,null,row.date,i,null,null);
          //    csv.push((fields2include).join(delimiter));
            }
         }
         else {
           let fields2include = retriveValues(4,exportModelData,row,null,null,null,i,null,null);
           if (fields2include) csv.push((fields2include).join(delimiter));//console.log("4")
         }
       }
     }
   });
   var csv_string = csv.filter(n => n).join('\n');
   var filename = exportModelData.name + "." + exportModelData.format; //'exportacion_' + new Date().toLocaleDateString() + "." + exportModelData.format;
   var link = document.createElement('a');
   link.style.display = 'none';
   link.setAttribute('target', '_blank');
   link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
   link.setAttribute('download', filename);
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
   if (listOfIdsExported.length > 0)  {
     let data2send = {
       list: listOfIdsExported,
       button: buttonName
     };
     post(`/server/request/setexported`,data2send).then((response)=>{
     });
   }
  }
  const displayPaidLeaveTypeName = (type) => {
    if (leaveModel && leaveModel.types) {
      let typeData = leaveModel.types.filter((e)=>e.def === type);
      if (typeData[0] && typeData[0].name) return typeData[0].name;
    }
  }
  const displayWorkedHours = (e) => {
    if (e.worked_hours > 0) return e.worked_hours;
    else if (e.holidays) return <BeachAccessIcon titleAccess="Vacaciones"/>
    else if (e.paidleave !== "0") return (
      <>
        <EventBusyIcon titleAccess="Permiso retribuido"/>
        <br/>
        {displayPaidLeaveTypeName(e.paidleave)}
      </>
    )
  }
  const displayRowData = (e,i) => {
    let workItems = (e && e.work_items) ? JSON.parse(e.work_items) : {};
    let dailyhours = (workHoursModel && workHoursModel.dailyhours) ? workHoursModel.dailyhours : null;
    let allowances = (workHoursModel && workHoursModel.allowances) ? workHoursModel.allowances : null;
    let kilometers = (workHoursModel && workHoursModel.kilometers) ? workHoursModel.kilometers : null;
    let basicData = <>
                      <TableCell key={"check"+i} className={classes.strongpointer}>
                        <Checkbox
                          color="default"
                          checked={checkedItems[i] ? checkedItems[i] : false}
                          onClick={()=>changeChecked(i)}
                        />
                      </TableCell>
                      <TableCell key={"name"+i}>{e.name}</TableCell>
                      <TableCell key={"employee"+i} align="right">{e.employee}</TableCell>
                      <TableCell key={"date"+i} align="right">{displayDate(e.date)}</TableCell>
                      <TableCell
                        key={"worked_hours"+i}
                        align="center"
                        rowSpan={(workItems.workorders && workItems.workorders.length) ? workItems.workorders.length : 1}
                        className = {(exportModel.max_worked_hours && e.worked_hours >= exportModel.max_worked_hours) ? classes.redbackground : null}
                        >{displayWorkedHours(e)}</TableCell>
                      <TableCell key={"time_started"+i} align="right">{e.time_started && e.time_started.substr(0,5)}</TableCell>
                      <TableCell key={"time_finished"+i} align="right">{e.time_finished && e.time_finished.substr(0,5)}</TableCell>
                    </>
    const displayNoZeros = (data) => {
      if (data) {
        let items = Object.keys(data);
        let toSend = items.filter((e)=>(!isNaN(data[e]) &&data[e]>0));
        return toSend.map((e)=><>{e}: {data[e]}<br/></>)
        }
    }
    let fullKMs = (exportModel.daily_kilometers && workItems.workorders) ? workItems.workorders.reduce((a,c)=>{
      a+=c[kilometers];
      return a;
    },0) : 0;
    if (!onlyErrors ||
        (exportModel.max_worked_hours && e.worked_hours >= exportModel.max_worked_hours) ||
        (fullKMs >= exportModel.daily_kilometers)
        )

    if (workItems.workorders && workItems.workorders.length > 0 && workItems.workorders !== null) return workItems.workorders.map((row,index)=>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={(i%2)?classes.bluebackground:null} key={"tablerow-"+i+"-"+index}>
            {index === 0 && <>{basicData}</>}
            {index > 0 && <TableCell key={"span"+i} colSpan={7}></TableCell>}
            <TableCell key={"workorder"+i+"-"+index} align="right">{row.workorder}</TableCell>
            <TableCell key={"dailyhours"+i+"-"+index} align="right">{dailyhours && displayNoZeros(row[dailyhours])}</TableCell>
            <TableCell
              key={"kilometers"+i+"-"+index}
              align="right"
              className = {(fullKMs >= exportModel.daily_kilometers) ? classes.redbackground : null}
              >
                {row[kilometers]}
              </TableCell>
            <TableCell key={"allowances"+i+"-"+index} align="right">{row[allowances]}</TableCell>
          </TableRow>
        )
      else if (!(workItems.workorders && workItems.workorders.length > 0)) return (
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={(i%2)?classes.bluebackground:null} key={"tablerow"+i+"-basicdata"}>
            {basicData}
            <TableCell key={"span"+i} colSpan={6}></TableCell>
          </TableRow>
        )
  }
  return (
    <Container maxWidth="lg" className={classes.contentTop}>
      <Grid container  justify="space-evenly" alignItems="center" spacing={4}  className={classes.spacingtwo}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            Exportación de datos
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="estado">Estado</InputLabel>
            <Select
              id="estadotipo"
              value={exportType}
              onChange={(e)=>retriveDataDB(e.target.value)}
              >
                <MenuItem value={0} key="new">No exportados</MenuItem>
                <MenuItem value={1} key="exported">Exportados</MenuItem>
                <MenuItem value={2} key="exported">Modificados después de exportados</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel control={<Switch checked={onlyErrors}/>} label="sólo alertas" onClick={enableOnlyErrors}/>
        </Grid>
        {exportType === 1 &&
          <>
            <Grid item xs={6} id="search-start-date">
              <TextField id="outlined-start-date" label={"Fecha inicio"} variant="outlined" type="date" defaultValue={startDate} onChange={changeStartDate}/>
            </Grid>
            <Grid item xs={6} id="search-end-date">
              <TextField id="outlined-end-date" label={"Fecha fin"} variant="outlined" type="date" defaultValue={endDate} onChange={changeEndDate}/>
            </Grid>
          </>
        }
      {displayexportData.length > 0 &&
        <>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 950 }} aria-label="simple table" className={classes.strongpointetable}>
                <TableHead key="tablehead">
                  <TableRow key="tablerow1" className={classes.strongpointeth}>
                    <TableCell key="check" className={classes.strongpointer}>
                      {!onlyErrors &&
                        <>
                        <Checkbox
                          color="default"
                          checked={allChecked}
                          onClick={changeAllChecked}
                        />
                        <Typography align="center" variant="body2" className={classes.strong}>
                          todos
                        </Typography>
                        </>
                      }
                    </TableCell>
                    <TableCell key="name" className={classes.strongpointer}>Nombre</TableCell>
                    <TableCell key="code" className={classes.strongpointer} align="right">Código</TableCell>
                    <TableCell key="date" className={classes.strongpointer} align="right">Fecha</TableCell>
                    <TableCell key="hours" className={classes.strongpointer}>Horas totales</TableCell>
                    <TableCell key="start" className={classes.strongpointer}>Comienzo</TableCell>
                    <TableCell key="end" className={classes.strongpointer}>Final</TableCell>
                    <TableCell key="wo" className={classes.strongpointer}>Orden de trabajo</TableCell>
                    <TableCell key="hourtypes" className={classes.strongpointer}>Horas trabajadas</TableCell>
                    <TableCell key="kms" className={classes.strongpointer}>Kilometros</TableCell>
                    <TableCell key="allowances" className={classes.strongpointer}>Dietas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayexportData.map((e,i) => displayRowData(e,i) )}
                </TableBody>
              </Table>
            </TableContainer>
            {1==0 &&
              <TablePagination
                rowsPerPageOptions={[15, 25, 50]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={<span>Filas:</span>}
                labelDisplayedRows={({ page }) => `Página ${page+1} de ${Math.ceil(totalRows/rowsPerPage)}`}
              />
            }

          </Grid>
          <Grid item xs={12}>
            {exportModel && exportModel.export && checkedItems.filter((e)=>e===true).length > 0 &&
              <>
                {exportModel.export.map((e,i)=>
                  <span className={classes.export_button}>
                    <Button key="send" variant="contained" onClick={()=>downloadFile(i,e.name)}>{e.name} fichero</Button>
                  </span>
                )}
              </>
            }
          </Grid>
        </>
      }
      </Grid>
        <Snackbar open={isSaved} autoHideDuration={6000} onClose={()=>setisSaved(false)}>
          <MuiAlert elevation={6} variant="filled"  onClose={()=>setisSaved(false)} severity="success" sx={{ width: '100%' }}>
            Guardado!
          </MuiAlert>
        </Snackbar>
      </Container>
  );

}
