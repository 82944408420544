import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExcellentExport from 'excellentexport';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { useStyles } from "./styles";
import {get,post} from "./commons";


function Row({ index,row,projects,employees,client,workOrder,workOrders,prodItems,prodSets,wo_IDs }) {
  const displayDate = (date) => {
    let newDate = new Date(date);
    if (newDate) return newDate.toLocaleDateString('es-ES')
  }
  const showemployeenmae = (row) => {
    if (row) {
      let emp = employees.filter((e)=>e.code===row);//console.log(emp)
      if (emp && emp[0] && emp[0].name) return emp[0].name;
    }
  }
  return (
    <tr>
      <td>{wo_IDs[row.id]}</td>
    {prodItems.map(e=>
      <td> {prodSets[row.id][e.id]}</td>
    )}
    </tr>
  );
}

export default function WorkReport({biz,shiftsigned,projects,iamamanager,iamadmin,isMobileSize,globalVariables,changeGlobalVariables}) {
  const classes = useStyles();

  const [employees, setEmployees] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [client, setClient] = React.useState(globalVariables.proddataReview_client ? globalVariables.proddataReview_client : null);
  const [workOrders, setWorkOrders] = React.useState([]);
  const [workOrder, setWorkOrder] = React.useState(globalVariables.proddataReview_workOrder ? globalVariables.proddataReview_workOrder : null);
  const [prodData, setProdData] = React.useState();
  const [prodSets, setProdSets] = React.useState();
  const [prodItems, setProdItems] = React.useState([]);
  const [clients_filtered, set_clients_filtered] = React.useState([]);
  const [wo_filtered, set_wo_filtered] = React.useState([]);
  const [closedWO, setClosedWO] = React.useState(false);
  const commonFilterItems = {set:[{connector:"equal"}],connector:"OR"};
  const [filterings, setFilterings] = React.useState([commonFilterItems]);
  const [displayFullSet, setDisplayFullSet] = React.useState("no");
  const [wo_IDs, setWO_IDs] = React.useState({});

  const padLeft = (n) => ("00" + n).slice(-2);
  const today = new Date();
  const todayFormated = today.getFullYear() + "-" + padLeft(1+today.getMonth()) + "-" + padLeft(today.getDate());

  const displayDate = (date) => {
    let newDate = new Date(date);
    if (newDate) return newDate.toLocaleDateString('es-ES')
  }

  const clearAll = () =>  {
    setClient();
    setWorkOrder();
    setProdData();
    setProdSets();
    setClosedWO(false);
    setFilterings([commonFilterItems]);
    setDisplayFullSet("no");
    setWO_IDs({});
  }
  const selectClient = (event, newValue) => {
    if (clients_filtered?.length!==clients?.length) {
      set_clients_filtered(clients);
      set_wo_filtered(workOrders);
      setWorkOrder();
    }
    else {
      set_wo_filtered(workOrders.filter(e=>!newValue || e.client === newValue.code))
      setClient(newValue);
      changeGlobalVariables("proddataReview_client", newValue);
    }
  }
  const selectWorkOrder = (event, newValue) => {
    setWorkOrder(newValue);
    changeGlobalVariables("proddataReview_workOrder", newValue);
    let newClient = clients.filter(e=>newValue?.client === e.code);
    if (newClient.length === 1) setClient(newClient[0]);
    else setClient();
    let newProject = projects?.filter(e=>newValue?.work_report === e.work_report);
    if (newProject.length === 1) {
      let newProjectKeys = Object.keys(newProject[0]);
      if (newProjectKeys.length > 0) {
        let newclient = clients.filter(e=>e.name===newProject[0].client);
        set_clients_filtered(newclient);
        let newwo = clients.filter(e=>e.name===newProject[0].client);
      }
      else {
        set_clients_filtered(clients);
        set_wo_filtered(workOrders);
      }
    }
    else {
      set_clients_filtered(clients);
      set_wo_filtered(workOrders);
    }
  }

  const searchDailyShifts = () => {
    let filteringsString = JSON.stringify(filterings.filter(e=>e.set?.filter(f=>f.item).length>0))
    let data = {};
    setProdData();
    let query = `/server/request/getprodfulldata`;
    if (client?.code) data.client=client.code;
    if (workOrder?.work_report) data.wo=workOrder.work_report;
    data.filters=filteringsString;
    post(query,data)
    .then((response) => {
      if (response.prod) {
        setProdData(response.prod);
        let fullSets = {}
        response.prod.forEach((item, i) => {
          fullSets[item.id] = item.wo;
        });
        setWO_IDs(fullSets);
        if (response.prod.length < 200) setDisplayFullSet("yes");
        else setDisplayFullSet("pending");
      }
      if (response.sets) {
        let setOrganized = {}
        response.sets.forEach((item, i) => {
          setOrganized[item.id] = {}
        });
        response.sets.forEach((item, i) => {
          setOrganized[item.id][item.row] = item.content
        });
        setProdSets(setOrganized);
      }
    })
  }

  useEffect(()=>{
    get(`/server/request/getworkorders?clients=true&open=${!closedWO}`)
    .then((response) => {
      if (response && response.wo?.length > 0) {
        setWorkOrders(response.wo);
        set_wo_filtered(response.wo);
      }
    });
  },[closedWO])
  useEffect(()=>{
    get('/server/request/getemployees')
    .then((response) => {
      if (response && response.employees?.length > 0) {
        setEmployees(response.employees);
      }
    });
    get(`/server/request/getworkorders?clients=true&open=true`)
    .then((response) => {
      if (response && response.wo?.length > 0) {
        setWorkOrders(response.wo);
        set_wo_filtered(response.wo);
      }
    });
    get(`/server/request/getclients?valsegment`)
    .then((response) => {
      if (response && response.clients?.length > 0) {
        setClients(response.clients);
        set_clients_filtered(response.clients);
      }
    });
    get(`/server/request/getproddataitems`)
    .then((response) => {
      if (response && response.proditems?.length > 0) {
        setProdItems(response.proditems)
      }
    });
  },[]);

  const downloadFile = () => {
    let options = {
       openAsDownload: true,
       format: 'xlsx',
       filename: `revision_produccion`
    };
    let fullDatSet2Export = prodData.map(row=>{
      let newset = prodItems.map(e=>prodSets[row.id][e.id]);
      newset.unshift(wo_IDs[row.id]?.toString());
      return newset;
    });
    let otherItems = [];
    if (workOrder) otherItems.push(['ORDEN DE TRABAJO',workOrder.work_report]);
    let headers = ["ORDEN",...prodItems.map(e=>e.name)];
    otherItems.push(headers);
    let sheets =   [
         {
             name: `datos de producción`,
             from: {
                 table: String/Element,
                 array: [
                   ...otherItems,
                   ...fullDatSet2Export
                 ]
             }
         }
     ]
    ExcellentExport.convert(options, sheets);
  }

  const showemployeenmae = (row) => {
    if (row) {
      let emp = employees.filter((e)=>e.code===row);//console.log(emp)
      if (emp && emp[0] && emp[0].name) return emp[0].name;
    }
  }
  const showcllientnmae = (row) => {
    if (row) {
      let emp = clients_filtered.filter((e)=>e.code===row);//console.log(emp)
      if (emp && emp[0] && emp[0].name) return emp[0].name;
    }
  }
  const changeConnector = (val,pos) => {
    let newfilterings = [...filterings];
    newfilterings[pos].connector=val;
    setFilterings(newfilterings);
  }
  const addNewSubfilter = (pos) => {
    let newfilterings = [...filterings];
    newfilterings[pos].set = [...newfilterings[pos].set,{connector:"equal"}]
    setFilterings(newfilterings);
  }
  const changeProdItem = (val,item,pos1,pos2) => {
    let newfilterings = [...filterings];
    let values = val.split(":-:");
    newfilterings[pos1].set[pos2][item] = values[0];
    if (values[1]) newfilterings[pos1].set[pos2]["type"] = values[1];
    setFilterings(newfilterings);
  }
  const filteringsDisplay = () => {
    let data2display = [];
    filterings.forEach((filterSubgroup,pos1)=>{
      data2display.push(
        <>
          <Grid item xs={12} id={"contition"+pos1}>
            <strong>Grupo {pos1+1} &nbsp; &nbsp;</strong>
            <Select
              value={filterSubgroup.connector}
              label="tiene que cumplir"
              onChange={(e)=>changeConnector(e.target.value,pos1)}
            >
             <MenuItem value="OR" key="OR">cualquiera</MenuItem>
             <MenuItem value="AND" key="AND">todas</MenuItem>
            </Select>
          </Grid>
        </>
      );
      filterSubgroup.set?.forEach((filterVal,pos2)=>{
        data2display.push(
          <>
          <Grid item xs={4} id="search">
            <FormControl fullWidth>
              <InputLabel id="elemento">elemento</InputLabel>
              <Select
                value={filterVal.item?.split(":-:")[0]}
                onChange={(e)=>changeProdItem(e.target.value,"item",pos1,pos2)}
              >
              {prodItems.filter(e=>["TEXT","NUMB","DECIM","PERCENT","DATE","EDIT_DATE","SUBCODE","DROPDOWN"].includes(e.type)).map(e=>
                <MenuItem value={e.id+":-:"+e.type} key={e.id} type={e.id}>{e.name}</MenuItem>
              )}
             </Select>
             </FormControl>
           </Grid>
           <Grid item xs={2} id="search">
             <FormControl fullWidth>
               <InputLabel id="group">grupo</InputLabel>
               <Select
                 value={filterVal.connector}
                 onChange={(e)=>changeProdItem(e.target.value,"connector",pos1,pos2)}
               >
                 <MenuItem value="equal" key="equal">=</MenuItem>
                 <MenuItem value="morequal" key="morequal">{">="}</MenuItem>
                 <MenuItem value="lessqual" key="lessqual">{"<="}</MenuItem>
                 <MenuItem value="different" key="different">&ne;</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} id="search">
            {["TEXT","NUMB","DECIM","PERCENT","DROPDOWN"].includes(filterVal.type) &&
              <TextField label="valor" onChange={(e)=>changeProdItem(e.target.value,"val",pos1,pos2)}>
                {filterVal.val}
              </TextField>
            }
            {["DATE","EDIT_DATE"].includes(filterVal.type) &&
              <TextField type="date" label="valor" onChange={(e)=>changeProdItem(e.target.value,"val",pos1,pos2)}>
                {filterVal.val}
              </TextField>
            }
            {filterVal.type === "SUBCODE" &&
              <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-employees"
                value={filterVal.val}
                onChange={(event, e)=>changeProdItem(e.subcode,"val",pos1,pos2)}
                options={employees.filter(e=>e.subcode)}
                getOptionLabel={(option) => `${option.name ? option.name : ""} - ${option.code ? option.code : ""} ${option.subcode ? " ("+option.subcode+")" : ""}`}
                renderInput={(params) => <TextField {...params} label="Empleado" placeholder="comienza a teclear el nombre..."/>}
              />
            }
          </Grid>
          </>
        );
      });
      data2display.push(
        <Grid item xs={2} id={"addsubfiltering"+pos1}>
          <Button key="send" variant="contained" onClick={()=>addNewSubfilter(pos1)}>+</Button>
        </Grid>
      );
    });
    data2display.push(
      <Grid item xs={12} id={"addfiltering"}>
        <Button key="send" variant="contained" onClick={()=>setFilterings([...filterings,{...commonFilterItems}])}>+ añadir grupo</Button>
      </Grid>
    )
    return data2display;
  }
  return (
    <div className={classes.contentBorder}>
      <Grid container direction="row" justify="center" alignContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            Revisión de datos de producción
          </Typography>
        </Grid>
        <Grid item xs={isMobileSize ? 6 : 3} id="search">
          <Autocomplete
            disablePortal
            fullWidth
            id="combo-box-wo"
            value={workOrder}
            onChange={selectWorkOrder}
            options={wo_filtered}
            getOptionLabel={(option) => `${option.work_report} ${option.description}`}
            className={wo_filtered?.length!==workOrders?.length ? classes.selectBlue : null}
            renderInput={(params) => <TextField {...params} label="Orden de trabajo" placeholder="comienza a teclear el nombre..."/>}
          />
        </Grid>
        <Grid item xs={isMobileSize ? 6 : 3} id="search">
          <Checkbox
            color="primary"
            checked={closedWO}
            onChange={()=>setClosedWO(!closedWO)}
            name="closedWOcheckbox" />
          Incluir ordenes cerradas
        </Grid>
        <Grid item xs={isMobileSize ? 6 : 3} id="search">
          <Autocomplete
            disablePortal
            fullWidth
            id="combo-box-clients"
            value={client}
            onChange={selectClient}
            options={clients_filtered}
            getOptionLabel={(option) => `${option.code} ${option.name}`}
            className={clients_filtered?.length!==clients?.length ? classes.selectBlue : null}
            renderInput={(params) => <TextField {...params} label="Cliente" placeholder="comienza a teclear el nombre..."/>}
          />
        </Grid>
        <Grid item xs={isMobileSize ? 6 : 3} id="search" align="right">
          <RestartAltIcon onClick={clearAll}/>
        </Grid>
        {filteringsDisplay()}
        <Grid item xs={isMobileSize ? 6 : 3} id="submit">
          {(workOrder || filterings[0]?.set[0].item) &&
            <Button
              key="submit-button"
              variant="outlined"
              onClick={searchDailyShifts}
              >
                Filtrar
            </Button>
          }
        </Grid>
        {prodData && prodData?.length > 0 &&
          <Grid item xs={isMobileSize ? 6 : 9} id="search" align="right">
              <Button
                key="send"
                variant="contained"
                onClick={downloadFile}
                startIcon={<img src="/images/file-excel.svg" className={classes.algin2middle20px}/>}>
                Descargar en Excel
              </Button>
          </Grid>
        }
        <Grid item xs={12} id="search" align="center">
          {prodData && prodData?.length === 0 &&
              <Typography variant="body" color="textSecondary">
                Sin datos para esta búsqueda
              </Typography>
          }
          {1===0 && !prodData && <CircularProgress/>}
        </Grid>
        <Grid item xs={12} id="search" align="center">
          {prodData && prodData?.length > 0 && displayFullSet === "yes" &&
              <table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                  <tr key="toprow">
                    <td className={classes.greybackgroundtd}> Orden </td>
                    {prodItems.map(e=>
                      <td className={classes.greybackgroundtd}>{e.name}</td>
                    )}
                  </tr>
                  {prodData.map((row,index) => (
                    <Row
                      key={index}
                      index={index}
                      row={row}
                      projects={projects}
                      employees={employees}
                      client={client}
                      workOrder={workOrder}
                      workOrders={workOrders}
                      prodItems={prodItems}
                      prodSets={prodSets}
                      wo_IDs={wo_IDs}
                      />
                  ))}
              </table>
            }
            {prodData && prodData?.length > 0 && displayFullSet === "pending" &&
              <>
                <Button key="displayFullSet" variant="contained" onClick={()=>setDisplayFullSet("yes")}>Visualizar</Button>
                <p>
                  La consulta tiene {prodData.length} filas y puede tardar más de {Math.round(prodData.length/200)} segundos en cargar.
                </p>
              </>
            }
          </Grid>
        </Grid>
    </div>
  );

}
