import { makeStyles } from '@mui/styles';

const commontablecontent = {
  fontSize: "1rem !important",
  padding: "3px !important",
  '& div': {
    '& div': {
      padding: "0 !important",
      '& textarea': {
        fontSize: "1rem !important",
        padding: "0 !important",
      },
    }
  }
}
const nowrapcommon = {
  whiteSpace: "nowrap",
  paddingLeft: "3px !important"
}
const tablecolors = ["f3f7fc","dce0f9","cbfcfa","aceead"];
const rowheader = {
  position: "sticky",
  zIndex: 3
}
const commonheadertable = {
  fontSize: "1rem !important",
  padding: "0 !important",
  textAlign: "right !important",
  userSelect: "none",
  width: "30px"
}
const stickyleft = {
   position: "sticky",
   left: 0,
   borderRight: "2px solid grey",
   zIndex: "9999 !important",
   adding: "0 !important",
   textAlign: "center !important"
}
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  spacingtwo: {
    padding: '32px 16px 0 16px'
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  appBar: {
    zIndex: 1251,
    position: 'sticky !important',
    top: 0
  },
  home_appBar: {
    zIndex: 1251,
    position: 'sticky !important',
    top: 0,
    backgroundColor: "white !important",
    boxShadow: "none !important"
  },
  home_logo: {
    padding: "20px",
    '& svg': {
      width: "calc(20vw)",
      maxWidth: "260px",
      minWidth: "170px"
      }
  },
  home_gap: {
    width: "calc(4vw)",
  },
  home_topbar_titles: {
    width: "calc(15vw)",
    color: "black",
    textAlign: "center",
    cursor: 'pointer'
  },
  home_topbar_button: {
    color: "black !important",
    borderRadius: "21px !important"
  },
  home_top_button: {
    color: "black !important",
    borderRadius: "21px !important",
    '& svg': {
      height: "1em !important",
      width: "1rem !important"
    }
  },
  home_img_top_text: {
    position: 'absolute',
    left: 'calc(45%)',
    top: 'calc(20%)',
    right: 'calc(10%)',
    zIndex: '1000',
    color: 'black',
    fontWeight: '300',
    fontSize: 'calc(2vw + 50%)',
    textAlign: 'center',
    textShadow: '0 0 1px rgba(0, 0, 0, 1)'

  },
  toolBar: {
    padding: "0 8px 0 8px !important"
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: '24px',
  },
  bold:{
    fontWeight: 'bold',
    fontSize:'1.25em'
  },
  boldRight:{
    fontWeight: 'bold',
    fontSize:'1.75em',
    textAlign:'right'
  },
  boldLeft:{
    color: 'blue',
    fontSize:'1.75em'
  },
  plain:{
    fontSize:'1.25em'
  },
  button:{
    marginTop: '16px',
  },
  button1:{
    marginTop: '8px',
  },
  button1black:{
    marginTop: '8px',
    color: 'black !important'
  },
  formControl:{
    margin: '8px',
    minWidth: 120,
  },
  right:{
    margin: '8px 0 0 0'
  },
  contentTop: {
    margin: '16px auto',
    paddingRight: '8px !important'
  },
  contentBorder: {
    margin: '16px',
  },
  catProf:{
    minWidth: 240,
  },
  nodisplay: {
    display: 'none',
  },
  full: {
    width: '100%'
  },
  fullHeight: {
    width: '100%',
    padding: '11px !important'
  },
  fullblue: {
    width: '100%',
    backgroundColor: '#ebf3fa'
  },
  fullred: {
    width: '100%',
    backgroundColor: 'red'
  },
  maxfull: {
    maxWidth: '100%'
  },
  full2: {
    width: '100%',
    background: "#f0f8fe"
  },
  half: {
    width: '50%'
  },
  loginbottom: {
    padding: '16px 0 16px 0 !important'
  },
  bottom: {
    marginTop: '16px',
  },
  topDouble: {
    marginTop: '56px'
  },
  strong: {
    fontWeight:'bold'
  },
  pointer: {
    cursor: 'pointer'
  },
  strongpointetable: {
//    position: 'absolute'
  },
  strongpointeth: {
    '& th': {
      fontWeight:'bold',
      position: 'sticky',
//      top: '64px',
      backgroundColor: '#d9d9d9',
      zIndex: 200
    }
  },
  strongpointer: {
    fontWeight:'bold',
    cursor: 'pointer'
  },
  projectTitle: {
    fontWeight:'bold',
    fontSize:'1.5em',
    backgroundColor: '#cfddf0',
    textAlign: 'center',
    display: 'flex'
  },
  rejectionComments: {
    fontWeight:'bold',
    fontSize:'1.5em',
    backgroundColor: '#f0cfdf',
    textAlign: 'center'
  },
  normal:{
    fontSize:'1.25em'
  },
  center:{
    textAlign:'center !important'
  },
  center100:{
    textAlign:'center !important',
    width: '100%'
  },
  hidden: {
    visibility: 'hidden'
  },
  topbottombutton: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important'
  },
  topgap: {
    paddingTop: '16px !important'
  },
  topbottomgap: {
    paddingTop: '16px !important',
    paddingBottom: '10px !important'
  },
  topgapheader: {
    paddingTop: '16px !important',
    height: "64px"
  },
  topgapright: {
    marginTop: '16px !important',
    textAlign:'right'
  },
  divcenterred: {
    '& div': {
      border: '1px solid red',
      margin: '0 auto'
    }
  },
  divcenter: {
    '& div': {
      margin: '0 auto'
    }
  },
  getnew: {
    textAlign: 'right',
    padding: '8px 16px 0px 16px !important',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  red: {
    color: "red",
      '& label': {
        color: "red",
      }
  },
  blue: {
    color: "blue !important"
  },
  filter_blue: {
    color: "blue !important",
    position: "absolute !important",
    right: "-10px",
    top: "-10px"
  },
  filter_grey: {
    position: "absolute !important",
    right: "-10px",
    top: "-10px"
  },
  bordercanvas: {
    position: "absolute",
    top: "100px",
    border: "1px solid #000000",
    left: "calc(50% - 150px)"
  },
  borderblacksolid: {
    border: "1px solid #000000"
  },
  dialogShifts: {
    padding: '16px',
    '& div': {
      fontSize: '1em',
      '& p': {
        fontSize: '1em',
      }
    }
  },
  shiftTable: {
    cursor: 'pointer',
    '& tr:nth-child(odd)': {
      backgroundColor:'#e9e9ff'
    }
  },
  shiftHeader: {
    '& th': {
    fontWeight: 700
    }
  },
  workOrderList: {
    backgroundColor:'#f9f9f9'
  },
  endblue: {
    textAlign:"end",
    background: "#d0e2f4",
    height: "40px",
    padding: "0 !important",
  },
  endshort: {
    textAlign:"end",
    height: "40px",
    padding: "0 !important",
  },
  title100: {
    textAlign:"center",
    width: "100%"
    },
  delEnd: {
    textAlign:"end"
  },
  logoclient:{
    width:"160px"
  },
  logo: {
    position: 'relative',
    '& svg': {
      height: "2.5em",
      width: "10em",
      fill: "#6e6e6e"
      }
  },
  logo_icon: {
    padding: '0 8px 0 0',
    '& svg': {
      height: "2em",
      width: "2em",
      fill: "#9e9e9e"
      }
  },
  topicon: {
    padding: '0 16px 0 0',
    cursor: 'pointer'
  },
  topbaruser: {
    width: "100%",
    textAlign: "center",
    fontSize: "0.9em !important",
    textTransform: "capitalize"
  },
  topbaruserbig: {
    width: "100%",
    textAlign: "center",
    fontSize: "1.3em !important",
    textTransform: "capitalize"
  },
  indented: {
    textIndent: "1em"
  },
  aboveInputLabel: {
    top: "-7px !important"
  },
  numberitem: {
  },
  circularprogressdialog: {
    padding: "130px",
    display: 'flex',
    width: 300,
    height: 300
  },
  notallowedcursor: {
    cursor: 'not-allowed'
  },
  bluebackground: {
    backgroundColor: "#ebffff"
  },
  redbackground: {
    border: "2px solid red !important",
    color: "red !important",
    fontWeight: "900 !important"
  },
  export_button: {
    marginRight: "10px"
  },
  commentStyle: {
    paddingBottom: "0 !important",
    paddingTop: "0 !important",
    fontStyle: 'italic',
    textAlign: 'right !important'
  },
  buttonStart: {
    backgroundColor: 'green !important',
    padding: '32px !important',
    fontSize: '2rem !important'
  },
  buttonStartDiv: {
    paddingTop: '48px !important',
    textAlign: 'center'
  },
  buttonDateDiv: {
    textAlign: 'center',
    marginTop: "30px !important"
  },
  bottomNav: {
    boxShadow: '0px -4px 8px 2px #0000003d',
    background: '#1f76cd !important'
  },
  bottomNavBox: {
    zIndex: 1251,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0
  },
  topSubNavBox: {
    position: 'fixed',
    top: 56,
    left: 0,
    right: 0
  },
  centerLabel: {
    textAlign: 'center',
    width: '100%',
    display: 'block !important'
  },
  centerLabelDiv: {
    width: '100%',
    textAlign: 'center'
  },
  bottomNavButton: {
    color: 'white',
    '& span, svg': {
      color: 'white'
    }
  },
  bottomNavButtonRed: {
    color: 'red',
    '& svg': {
      color: '#07fcff',
      animation: "$spin2 4s linear infinite"
    },
    '& span': {
      color: 'white'
    }
  },
  "@keyframes spin2": {
    "100%": {
        transform: "rotate(360deg)"
    }
  },
  nodecor: {
    textDecoration: 'none',
    color: 'black'
  },
  backgroundWarning: {
    backgroundColor: '#fff0e3'
  },
  numberButton: {
    border: '0px !important'
  },
  numberButtonMiddle: {
    border: '0px !important',
    width: '4em'
  },
  algin2bottom: {
    verticalAlign: "bottom"
  },
  algin2middle: {
    verticalAlign: "middle"
  },
  algin2middle20px: {
    verticalAlign: "middle",
    width: "20px"
  },
  algin2middle2wrap: {
    verticalAlign: "middle",
    padding: '2px'
  },
  numberButtonGroup:{
    minHeight: '2.5em'
  },
  editedTime:{
    fontSize: '0.9em !important',
    paddingTop: 0
  },
  redpointer: {
    color: "red",
    cursor: 'pointer'
  },
  bluepointer: {
    color: "blue",
    cursor: 'pointer'
  },
  coloredRows: {
    backgroundColor:'#e9e9ff'
  },
  smallIcon: {
    width: "20px !important",
    height: "20px !important"
  },
  redAlert: {
    backgroundColor: 'red',
    color: 'white',
    padding: '15px',
    borderRadius: '4px',
    textAlign: 'center'
  },
  end: {
    textAlign:"end"
  },
  unselectable: {
    userSelect: "none"
  },
  grey: {
    color: '#9e9e9e !important',
    userSelect: "none"
  },
  endflow: {
    textAlign:"end",
    display: "flow",
    userSelect: "none"
  },
  endflowgrey: {
    textAlign:"end",
    color: '#9e9e9e !important',
    display: "flow",
    userSelect: "none"
  },
  greynomarings: {
    color: '#9e9e9e',
    margin: 0
  },
  nomarings: {
    margin: 0
  },
  mobileTable: {
    minWidth: '200px !important'
  },
  datarow0: {
    backgroundColor: '#f4f8f9'
  },
  datarow1: {
    backgroundColor: '#e8eef6',
    '& td:first-child': {
      borderRadius: '10px'
    },
    '& td:last-child': {
      borderRadius: '10px'
    }
  },
  tableWO: {
    border: 0,
    boxShadow: 'none !important'
  },
  bottomWO: {
    position: 'relative'
  },
  bottomWOend: {
    textAlign:"end",
    float: 'right'
  },
  textcenter: {
    textAlign: 'center !important'
  },
  dateWo: {
    verticalAlign: "middle",
    fontWeight: 'bold',
    fontSize:'1.2em'
  },
  datacolumn1: {
    border: '0 !important'
  },
  pagination: {
    height: "120px"
  },
  spreadsheetDiv: {
    overflowX: "scroll",
    width: "100%",
    '& table': {
      '& tbody': {
        '& tr:has(th):has(isgrey)': {
          backgroundColor: "#f3f3f3"
          }
       }
    }
  },
  wrongdata: {
    backgroundColor: "red",
    color:"white"
  },
  yellow: {
    '& fieldset': {
      borderColor: "white !important"
    },
    '& svg': {
      color: "white"
    }
  },
  yellowShort: {
    '& fieldset': {
      border: 0
    },
    '& svg': {
      color: "white"
    }
  },
  padding9: {
    padding: '9px',
    color: '#646464',
    display: 'inline-flex'
  },
  padding0: {
    padding: '0 !important',
  },
  nodecors: {
    color: 'inherit'
  },
  wrapping: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  wrappingblue: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    background: "#d0e2f4",
    height: "40px",
    padding: "7px !important",
  },
  tableHeader: {
    minWidth: '200px',
  },
  tableContentSimple: {
    left: 0,
    backgroundColor: "white",
    ...commonheadertable,
    ...rowheader
  },
  tableContentSimpleBlue: {
    left: 0,
    backgroundColor: "white",
    color: "#4272a2 !important",
    fontWeight: "900 !important",
    ...commonheadertable,
    ...rowheader
  },
  tableContentShort: {
    padding: "0 !important",
    width: "30px",
    textAlign: "center !important"
  },
  tableContentShortHeader0: {
    padding: "0 !important",
    width: "30px",
    textAlign: "center !important",
    left: "0 !important",
    backgroundColor: "white",
    ...rowheader
  },
  tableContentShortHeader: {
    padding: "0 !important",
    width: "30px",
    textAlign: "center !important",
    left: "42px !important",
    backgroundColor: "white",
    ...rowheader
  },
  tableContent: {
    background: `#${tablecolors[0]} !important`,
    ...commontablecontent
  },
  tableContent2: {
    background: `#${tablecolors[1]} !important`,
    ...commontablecontent
  },
  tableContent3: {
    background: `#${tablecolors[2]} !important`,
    ...commontablecontent
  },
  tableContent4: {
    background: `#${tablecolors[3]} !important`,
    ...commontablecontent
  },
  tableContentred: {
    backgroundColor: "red",
    color:"white",
    fontSize: "1rem !important",
    padding: "3px !important",
    '& div': {
      '& div': {
        padding: "0 !important",
        '& textarea': {
          fontSize: "1rem !important",
          padding: "0 !important",
        },
      }
    }
  },
  tableContentblue: {
    fontSize: "1rem !important",
    border: "2px solid blue",
    borderBottom: "2px solid blue !important",
    padding: "3px !important",
    '& div': {
      '& div': {
        padding: "0 !important",
        '& textarea': {
          fontSize: "1rem !important",
          padding: "0 !important",
        }
      }
    }
  },
  tableContentgreen: {
    fontSize: "1rem !important",
    border: "2px solid green",
    borderBottom: "2px solid green !important",
    padding: "3px !important",
    '& div': {
      '& div': {
        padding: "0 !important",
        '& textarea': {
          fontSize: "1rem !important",
          padding: "0 !important",
        }
      }
    }
  },
  filterDiv: {
  },
  tableColumnSelected: {
    visibility: "hidden",
    position: "absolute",
    pointerEvents: "none",
    boxSizing: "border-box",
    background: "#1f76cd36",
    border: "2px #1f76cd solid",
    top: "42px",
    zIndex: 999,
    height: "calc(100% - 42px)"
  },
  prodTable: {
    position: "relative",
    maxWidth: "calc(100vw)",
    maxHeight: "calc(100vh - 260px)"
  },
  prodTable2: {
    position: "relative",
    maxWidth: "calc(100vw)",
    maxHeight: "calc(100vh - 360px)"
  },
  tableRowNotSelected: {
    minWidth: "calc(100vw - 40px)",
    borderSpacing: "1px !important"
  },
  tableRowSelected: {
    background: "#1f76cd36",
    border: "2px #1f76cd solid",
    minWidth: "calc(100vw - 40px)",
    borderSpacing: "1px !important"
  },
  tableColumnSelected: {
    background: "#1f76cd36",
    fontSize: "1rem !important",
    padding: "3px !important",
    '& div': {
      '& div': {
        padding: "0 !important",
        '& textarea': {
          fontSize: "1rem !important",
          padding: "0 !important",
        }
      }
    }
  },
  greybackground: {
    backgroundColor: "#f3f3f3 !important"
  },
  greybackgroundtd: {
    backgroundColor: "#f3f3f3 !important",
    minWidth: "150px",
    fontWeight: 800
  },
  nowrap: {
    background: `#${tablecolors[0]} !important`,
    ...nowrapcommon
  },
  nowrap2: {
    background: `#${tablecolors[1]} !important`,
    ...nowrapcommon
  },
  nowrap3: {
    background: `#${tablecolors[2]} !important`,
    ...nowrapcommon
  },
  nowrap4: {
    background: `#${tablecolors[3]} !important`,
    ...nowrapcommon
  },
  nowrapblue: {
    background: "#1f76cd36 !important",
    whiteSpace: "nowrap",
    paddingLeft: "3px !important"
  },
  white: {
    color: "white"
  },
  darktbluepointer: {
    color: "#4272a2",
    cursor: 'pointer'
  },
  lightblue: {
    color: "#d0e2f4"
  },
  width60: {
    width: "60%"
  },
  width20: {
    width: "20%",
    textAlign: 'center !important'
  },
  bottomButtons: {
    marginTop: '16px'
  },
  dropdownmenutitle: {
    color: "#1f76cd !important",
    fontWeight: 'bold !important',
    fontSize:'1.1em !important'
  },
  storeButton: {
    position: "sticky",
    top: "60px"
  },
  no_outline: {
    outline: "none"
  },
  prodwoitem: {
    backgroundColor: "red",
    minHeight: "64px",
    height: "100%"
  },
  prodwoitem2: {
    height: "33px"
  },
  prodwoline: {
    backgroundColor: "grey",
    height: "2px"
  },
  minimg: {
    height: "56px",
    verticalAlign: "top",
    paddingRight: "8px"
  },
  minimgdiv: {
    overflow: "hidden",
    width: "100%",
    height: "56px"
  },
  minimgbutton: {
    position: "relative",
    marginTop: "0px",
    marginRight: "16px"
  },
  imgbar: {
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  mulimage: {
    maxWidth: "300px"
  },
  mulimagemobile: {
    maxWidth: "100%"
  },
  columnImg: {
  float: "left",
  padding: "0"
},
  rowImg: {
  display: "flex"
},
tinyicon: {
  height: "0.7em !important"
},
stickyHeader: {
  ...stickyleft,
  ...commonheadertable
},
stickyHeaderblue: {
  background: "#1f76cd36",
    color: "#4272a2 !important",
    fontWeight: "900 !important",
  ...stickyleft,
  ...commonheadertable
},
selectBlue: {
  backgroundColor: "#ccfcfa"
},
reactCrop: {
  padding: "1px",
  width: "95%"
},
aligncenter: {
  textAlign: "center"
},
aligncenterbutton: {
  position: 'relative',
  width: '100%',
  '& div': {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
},
wo_header: {
  backgroundColor: '#8f8f8f',
  textAlign: 'center',
  borderRadius: '4px',
  padding: '8px',
  marginTop: '8px !important',
  '& p': {
    color: '#ffffff',
  }
},
itemDetails:{
  backgroundColor: '#f8f8f8 !important',
  borderRadius: '4px',
  border: '1px black solid',
  padding: '6px'
},
prodsection0: {
  backgroundColor: "#1976d2 !important",
  color: "white !important",
  width: '100%'
},
prodsection1: {
  backgroundColor: "#c5e1e9 !important",
  color: "black !important",
  width: '100%'
},
prodsection2: {
  backgroundColor: "#a9e3dd !important",
  color: "black !important",
  width: '100%'
},
borderRadius10: {
  borderRadius: '10px',
  minWidth: "60vw"
},
home_subheaders: {
  fontFamily: 'Garamond, serif !important',
  fontSize: '2rem !important',
  fontWeight: '500 !important',
  textShadow: '0 0 1px #000000'
},
home_bottom_nav_p: {
  minHeight: '40px',
  textAlign: 'center',
  cursor: 'pointer'
},
icon_size: {
  width: "24px"
},
padding8: {
  padding: '8px'
}
}));
